import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Footer from './footer'
import './layout.scss'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'The Hit Factory is a web design and development studio in Wellington.' },
          ]}
          style={[{
              "cssText": `
                  .footer h1,
                  .footer > div {
                     visibility: hidden;
                  }
              `
          }]}
        />
        <div className="main">
          {children}
        </div>
        <Footer />
      </>
    )}
  />
)

export default Layout
