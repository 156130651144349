import React from 'react'
import './footer.scss'

const Footer = () => (
  <div className="footer">
     <h1 className="title">The Hit Factory</h1>
    <div className="address">47 Waiapu Road, Kelburn</div>
    <div className="address">Wellington, New Zealand</div>
    <div className="phone"><a href="tel:+64226572178">+64 22 657 2178</a></div>
    <div className="email"><a href="mailto:hello@hitfactory.co.nz">hello@hitfactory.co.nz</a></div>
  </div>
)

export default Footer
